/* globals CSS */
import $ from 'jquery'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import * as basicScroll from 'basicscroll'

class BlockListJobs extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$sliderCaption = $('[data-slider-caption]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
  }

  connectedCallback () {
    this.initSlider()
    if (window.CSS && CSS.supports('color', 'var(--fake-var)') && $('.parallax', this).length) {
      this.$parallax = basicScroll.create({
        elem: $('.parallax', this)[0],
        from: 'top-bottom',
        to: 'middle-top',
        direct: this.$[0],
        props: {
          '--slideUp': {
            from: '10%',
            to: '0%'
          },
          '--slideDown': {
            from: '-10%',
            to: '0%'
          }
        }
      })
      this.$parallax.start()
    }
  }

  initSlider () {
    const configCaption = {
      navigation: {
        nextEl: this.$buttonNext,
        prevEl: this.$buttonPrev
      },
      slidesPerView: 4,
      speed: 600,
      loop: false,
      simulateTouch: false,
      a11y: this.props.a11y,
      watchOverflow: true,
      breakpoints: {
        1279: {
          slidesPerView: 3
        },
        1023: {
          slidesPerView: 2
        },
        575: {
          slidesPerView: 1
        }
      }
    }

    this.sliderCaption = new Swiper(this.$sliderCaption, configCaption)
  }
}

window.customElements.define('flynt-block-list-jobs', BlockListJobs, { extends: 'div' })
