import $ from 'jquery'

class BlockChronical extends window.HTMLDivElement {
  constructor (self) {
    self = super(self)
    self.$ = $(self)
    self.resolveElements()
    return self
  }

  resolveElements () {
    this.$window = $(window)
    this.$timeline = $('.timeline', this)
    this.$video = this.$timeline.find('.video')
    this.$event = this.$timeline.find('.event')
  }

  connectedCallback () {
    this.$video.one('click', $(this).find('.video-playButton'), this.loadVideo)

    this.addExtraMargin(this.$event)
    $(window).on('resize', () => {
      this.addExtraMargin(this.$event)
    })
  }

  loadVideo () {
    this.iframe = $(this).find('iframe')
    this.iframe.attr('src', this.iframe.data('src'))
    $(this).find('.video-player').addClass('video-player--isLoading')
    this.iframe.one('load', () => {
      $(this).find('.video-player').removeClass('video-player--isLoading').addClass('video-player--isLoaded')
      $(this).find('.poster-image').addClass('figure-image--isHidden')
    })
  }

  addExtraMargin (item) {
    item.each((i, el) => {
      if ($(el).next().length) {
        const diffRightLeft = $(el).next().offset().top - $(el).offset().top
        const thisYear = $(el).data('year')
        const nextYear = $(el).next().data('year')

        if (diffRightLeft < 30 && diffRightLeft > -1) {
          if (nextYear >= thisYear) {
            $(el).addClass('extra-margin').next().removeClass('extra-margin')
          } else {
            $(el).removeClass('extra-margin').next().addClass('extra-margin')
          }
        }
      }
    })
  }
}

window.customElements.define('flynt-block-chronical', BlockChronical, { extends: 'div' })
