/* globals CSS */
import $ from 'jquery'
import Swiper from 'swiper'
import * as basicScroll from 'basicscroll'
import 'swiper/dist/css/swiper.min.css'

class SliderImageText extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$sliderImage = $('[data-slider-image]', this)
    this.$sliderCaption = $('[data-slider-caption]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
    this.$sliderPagination = $('[data-slider-pagination]', this)
  }

  connectedCallback () {
    this.initSlider()
    if (window.CSS && CSS.supports('color', 'var(--fake-var)') && $('.parallax', this)[0]) {
      this.$parallax = basicScroll.create({
        elem: $('.parallax', this)[0],
        from: 'top-bottom',
        to: 'bottom-top',
        direct: this.$[0],
        props: {
          '--slideDown': {
            from: '-20%',
            to: '20%'
          }
        }
      })
      this.$parallax.start()
    }
  }

  initSlider () {
    const { options } = this.props
    const configImage = {
      navigation: {
        nextEl: this.$buttonNext,
        prevEl: this.$buttonPrev
      },
      pagination: {
        el: this.$sliderPagination,
        type: 'fraction'
      },
      speed: 600,
      loop: true,
      a11y: options.a11y
    }

    const configCaption = {
      loop: true,
      a11y: options.a11y
    }

    if (options.autoplay && options.autoplaySpeed) {
      configImage['autoplay'] = {
        delay: options.autoplaySpeed
      }
    }
    if (this.$sliderImage.find('.swiper-slide').length > 1) {
      this.sliderImage = new Swiper(this.$sliderImage, configImage)
      this.sliderCaption = new Swiper(this.$sliderCaption, configCaption)

      this.sliderImage.controller.control = this.sliderCaption
      this.sliderCaption.controller.control = this.sliderImage
    }
  }
}

window.customElements.define('flynt-slider-image-text', SliderImageText, { extends: 'div' })
