/* globals CSS */
import $ from 'jquery'
import * as basicScroll from 'basicscroll'

class HeroHeader extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
  }

  resolveElements () {}

  connectedCallback () {
    if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
      this.$parallax = basicScroll.create({
        elem: $('.parallax', this)[0],
        from: 'top-bottom',
        to: 'bottom-top',
        direct: this.$[0],
        props: {
          '--slideDown': {
            from: '-20%',
            to: '20%'
          },
          '--slideUp': {
            from: '15%',
            to: '-15%'
          }
        }
      })
      this.$parallax.start()
    }
  }
}

window.customElements.define('flynt-hero-header', HeroHeader, { extends: 'div' })
