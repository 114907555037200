/* globals CSS */
import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import * as basicScroll from 'basicscroll'

class HeroTeaser extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  bindFunctions () {
    this.expandModal = this.expandModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.closeOnDimmer = this.closeOnDimmer.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-hero-modal]', this.expandModal)
    this.$.on('click', '[data-hero-modal-close]', this.closeModal)
    this.$modal.on('click', this.closeOnDimmer)
  }

  resolveElements () {
    this.$sliderModal = $('[data-hero-modal]', this)
    this.$modal = $('.hero-modal', this)
    this.$modalScroll = $('.hero-modal-inner', this)
    this.$body = $('html, body')
  }

  connectedCallback () {
    if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
      this.$parallax = basicScroll.create({
        elem: $('.parallax', this)[0],
        from: 'top-bottom',
        to: 'bottom-top',
        direct: this.$[0],
        props: {
          '--slideDown': {
            from: '-20%',
            to: '20%'
          }
        }
      })
      this.$parallax.start()
    }
  }

  expandModal () {
    disableBodyScroll(this.$modalScroll.get(0))
    this.$modal.fadeIn({
      start: function () {
        $(this).css({
          display: 'flex'
        })
      }
    })
  }

  closeOnDimmer (e) {
    if ($(e.target).closest('.hero-modal-figure').length === 0) {
      this.closeModal()
    }
  }

  closeModal () {
    enableBodyScroll(this.$modalScroll.get(0))
    this.$modal.fadeOut(300)
  }
}

window.customElements.define('flynt-hero-teaser', HeroTeaser, { extends: 'div' })
