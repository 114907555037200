/* globals CSS */
import $ from 'jquery'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
import 'objectFitPolyfill'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import * as basicScroll from 'basicscroll'

class HeroImageTextSlider extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$sliderImage = $('[data-slider-image]', this)
    this.$sliderCaption = $('[data-slider-caption]', this)
    this.$slides = $('.swiper-slide', this.$sliderImage)
    this.$sliderPagination = $('[data-slider-pagination]', this)
    this.$document = $(document)
  }

  connectedCallback () {
    this.initSlider()
    if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
      this.$parallax = basicScroll.create({
                                            elem: $('.parallax', this)[0],
                                            from: 'top-bottom',
                                            to: 'bottom-top',
                                            direct: this.$[0],
                                            props: {
                                              '--slideDown': {
                                                from: '-20%',
                                                to: '20%'
                                              }
                                            }
                                          })
      this.$parallax.start()
    }
  }

  initSlider () {
    const { options } = this.props
    var titles = []
    this.$slides.each(function () {
      titles.push($(this).data('title'))
    })
    const config = {
      a11y: options.a11y,
      effect: 'fade',
      pagination: {
        el: this.$sliderPagination,
        clickable: true,
        renderBullet: function (index, className) {
          return '<button class="swiper-pagination-titles--item ' + className + '" data-aos="fade-up" data-aos-offset="0" data-aos-delay="' + index * 2 + '00"><span class="title">' + titles[index] + '</span></button>'
        }
      },
      on: {
        slideChangeTransitionStart: () => {
          const $currentSlide = $(this.sliderImage.slides[this.sliderImage.activeIndex])
          this.loadImage($currentSlide)
        }
      }
    }

    const configCaption = {
      a11y: options.a11y
    }

    if (options.autoplay && options.autoplaySpeed) {
      config['autoplay'] = {
        delay: options.autoplaySpeed
      }
    }

    this.sliderImage = new Swiper(this.$sliderImage, config)
    this.sliderCaption = new Swiper(this.$sliderCaption, configCaption)

    this.sliderImage.controller.control = this.sliderCaption
    this.sliderCaption.controller.control = this.sliderImage

    const $currentSlide = this.$sliderImage.find('.swiper-slide-active')
    this.loadImage($currentSlide)
  }

  loadImage (slide) {
    const image = $('img', slide)[0]
  }
}

window.customElements.define('flynt-hero-image-text-slider', HeroImageTextSlider, { extends: 'div' })
