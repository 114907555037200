import $ from 'jquery'

class FormContact extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
  }

  resolveElements () {
    this.$fileInput = $('[type=file]', this)
    this.$placeholders = $('[placeholder]', this)
    this.$datepickerInput = $('.gfield_best_before_date', this)
  }

  connectedCallback () {
    this.$.on('change', this.$fileInput.selector, this.showFileName.bind(this))
    this.$.on('change keyup', this.$placeholders.selector, this.placeholderPolyfill.bind(this))
    this.placeholderPolyfillOnLoad(this.$placeholders)
    this.$datepickerInput.on('click', this.$datepickerInput, this.shrinkDatePickerLabel.bind(this))
  }

  showFileName () {
    const label = this.$fileInput.closest('.gfield').find('.gfield_label')
    if (this.$fileInput.val()) {
      label.text(this.$fileInput.val().split('\\').pop())
    }
  }

  shrinkDatePickerLabel () {
    this.$datepickerInput.find('.gfield_label').addClass('gfield_label_smaller')
  }

  placeholderPolyfill (e) {
    const input = e.target
    this.addRemoveClass(input)
  }

  placeholderPolyfillOnLoad (inputs) {
    inputs.each((i, input) => {
      this.addRemoveClass(input)
    })
  }

  addRemoveClass (input) {
    input.classList[input.value ? 'remove' : 'add']('placeholder-shown')
    input.classList[input.value ? 'add' : 'remove']('with-value')
  }
}

window.customElements.define('flynt-form-contact', FormContact, {
  extends: 'div'
})
