import $ from 'jquery'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

class NavigationBurger extends window.HTMLElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.bindFunctions()
    this.bindEvents()
    this.resolveElements()
  }

  bindFunctions () {
    this.triggerMenu = this.triggerMenu.bind(this)
  }

  bindEvents () {
    this.$.on('click', '[data-toggle-menu]', this.triggerMenu)
  }

  resolveElements () {
    this.$menu = $('.menu', this)
    this.$body = $('html, body')
    this.$panelToggler = $('.menu-toggler', this)
    this.$menuScroll = $('.menu-scroll', this)
  }

  connectedCallback () {
    this.$.on('click', '[aria-controls]', this.togglePanel)
    this.openActivePanel()
  }

  triggerMenu (e) {
    this.$.toggleClass('flyntComponent-menuIsOpen')
    if (this.$.hasClass('flyntComponent-menuIsOpen')) {
      disableBodyScroll(this.$menuScroll.get(0))
    } else {
      enableBodyScroll(this.$menuScroll.get(0))
    }
  }

  togglePanel () {
    const $panel = $(this)
    if ($panel.attr('aria-expanded') === 'true') {
      $panel.attr('aria-expanded', 'false')
      $panel.next().attr('aria-hidden', 'true').slideUp()
    } else {
      $panel.attr('aria-expanded', 'true')
      $panel.next().attr('aria-hidden', 'false').slideDown({
        start: function () {
          $(this).css({
            display: 'flex'
          })
        }
      })
    }
  }

  openActivePanel () {
    this.$panelToggler.each(function () {
      if ($(this).parent().hasClass('menu-item--current') || $(this).parent().hasClass('menu-sub-item--current')) {
        $(this).attr('aria-expanded', 'true')
        $(this).next().attr('aria-hidden', 'false').slideDown()
      }
    })
  }
}

window.customElements.define('flynt-navigation-burger', NavigationBurger, {
  extends: 'nav'
})
