import $ from 'jquery'

class BlockVideoOembed extends window.HTMLDivElement {
  constructor (self) {
    self = super(self)
    self.$ = $(self)
    self.resolveElements()
    return self
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.loadVideo = this.loadVideo.bind(this)
    this.$posterImage = $('.figure-image', this)
    this.$videoPlayer = $('.video-player', this)
    this.$playButton = $('.video-playButton', this)
    this.$video = $('.video', this)
    this.$iframeHolder = $('.iframeHolder', this)
    this.$window = $(window)
    this.$videoCaption = $('.video-caption', this)
  }

  connectedCallback () {
    this.props = this.getInitialProps()
    this.$iframeHolder.html(this.props.video)
    this.$video.one('click', this.$playButton.selector, this.loadVideo.bind(this))
  }

  loadVideo () {
    const $iframe = this.$.find('iframe')
    $iframe.one('load', this.videoIsLoaded.bind(this))
    $iframe.attr('src', $iframe.data('src'))
    this.$videoPlayer.addClass('video-player--isLoading')
  }

  videoIsLoaded () {
    this.$videoPlayer.removeClass('video-player--isLoading')
    this.$videoPlayer.addClass('video-player--isLoaded')
    this.$posterImage.addClass('figure-image--isHidden')
  }
}

window.customElements.define('flynt-block-video-oembed', BlockVideoOembed, { extends: 'div' })
