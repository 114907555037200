/* globals CSS */
import $ from 'jquery'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'
import 'lazysizes/plugins/object-fit/ls.object-fit'
import 'lazysizes/plugins/respimg/ls.respimg'
import 'objectFitPolyfill'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'
import throttle from 'lodash.throttle'
import * as basicScroll from 'basicscroll'

class HeroVideoTextSlider extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.resolveElements()
  }

  getInitialProps () {
    let data = {}
    try {
      data = JSON.parse($('script[type="application/json"]', this).text())
    } catch (e) {}
    return data
  }

  resolveElements () {
    this.$sliderVideo = $('[data-slider-video]', this)
    this.$sliderCaption = $('[data-slider-caption]', this)
    this.$slides = $('.swiper-slide', this.$sliderVideo)
    this.$sliderPagination = $('[data-slider-pagination]', this)
    this.$videoPlayer = $('.video-player', this)
    this.$document = $(document)
  }

  connectedCallback () {
    this.initSlider()
    if (window.CSS && CSS.supports('color', 'var(--fake-var)')) {
      this.$parallax = basicScroll.create({
        elem: $('.parallax', this)[0],
        from: 'top-bottom',
        to: 'bottom-top',
        direct: this.$[0],
        props: {
          '--slideDown': {
            from: '-20%',
            to: '20%'
          }
        }
      })
      this.$parallax.start()
    }
  }

  initSlider () {
    const { options } = this.props
    var titles = []
    this.$slides.each(function () {
      titles.push($(this).data('title'))
    })
    const config = {
      a11y: options.a11y,
      effect: 'fade',
      pagination: {
        el: this.$sliderPagination,
        clickable: true,
        renderBullet: function (index, className) {
          return '<button class="swiper-pagination-titles--item ' + className + '" data-aos="fade-up" data-aos-offset="0" data-aos-delay="' + index * 2 + '00"><span class="title">' + titles[index] + '</span></button>'
        }
      },
      on: {
        slideChangeTransitionStart: () => {
          const $previousSlide = $(this.sliderVideo.slides[this.sliderVideo.previousIndex])
          const $currentSlide = $(this.sliderVideo.slides[this.sliderVideo.activeIndex])

          this.pauseVideo($previousSlide)
          this.loadVideo($currentSlide)
        }
      }
    }

    const configCaption = {
      a11y: options.a11y
    }

    if (options.autoplay && options.autoplaySpeed) {
      config['autoplay'] = {
        delay: options.autoplaySpeed
      }
    }

    this.sliderVideo = new Swiper(this.$sliderVideo, config)
    this.sliderCaption = new Swiper(this.$sliderCaption, configCaption)

    this.sliderVideo.controller.control = this.sliderCaption
    this.sliderCaption.controller.control = this.sliderVideo

    const $currentSlide = this.$sliderVideo.find('.swiper-slide-active')
    this.loadVideo($currentSlide)
  }

  loadVideo (slide) {
    if (!slide.videoInitialiazed) {
      const video = $('video', slide)[0]
      const videoSource = $('video source', slide)
      videoSource.attr('src', videoSource.data('src'))
      video.load()
      this.playVideo(slide)
      video.addEventListener('play', this.videoIsLoaded(slide))
      slide.videoInitialiazed = true
    } else {
      this.playVideo(slide)
    }
  }

  videoIsLoaded (slide) {
    $('.video-player', slide).addClass('video-player--isLoaded')
    $('.poster', slide).addClass('poster-image--isHidden')
  }

  playVideo (slide) {
    const video = $('video', slide)[0]
    video.play()
    this.$document.on('scroll', throttle(() => {
      this.checkScroll(video)
    }, 200))
  }

  pauseVideo (slide) {
    const video = $('video', slide)[0]
    video.pause()
  }

  checkScroll (video) {
    const x = video.offsetLeft
    const y = video.offsetTop
    const w = video.offsetWidth
    const h = video.offsetHeight
    const r = x + w
    const b = y + h
    let visibleX,
      visibleY,
      visible

    visibleX = Math.max(0, Math.min(w, window.pageXOffset + window.innerWidth - x, r - window.pageXOffset))
    visibleY = Math.max(0, Math.min(h, window.pageYOffset + window.innerHeight - y, b - window.pageYOffset))

    visible = visibleX * visibleY / (w * h)

    if (visible) {
      video.play()
    } else {
      video.pause()
    }
  }
}

window.customElements.define('flynt-hero-video-text-slider', HeroVideoTextSlider, { extends: 'div' })
