
import $ from 'jquery'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'

class SliderImage extends window.HTMLDivElement {

  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
  }

  resolveElements () {
    this.$slider = $('[data-slider-image]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)
    this.$window = window
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const config = {
      draggable: true,
      preventClicks: false,
      //loop: true,
      initialSlide: 0,
      preventClicksPropagation: false,
      touchMoveStopPropagation: true,
      touchReleaseOnEdges: true,
      slideClass:                   'swiper-slide',
      wrapperClass:                 'swiper-wrapper',
      speed: 500,
      navigation: {
        'nextEl': this.$buttonNext,
        'prevEl': this.$buttonPrev,
        'disabledClass':            'is-disabled',
        'hiddenClass':              'is-hidden'
      },
      'a11y': {
        'enabled': false
      },
      slidesPerView: 1,
      spaceBetween: 20,
      breakpointsInverse: true,
      breakpoints: {
        1024: {
          'spaceBetween': 50,
          'slidesPerView': 3
        },

        768: {
          'slidesPerView': 2,
          'spaceBetween': 25
        },

        767: {
          'slidesPerView': 1

        }
      }
    }
    this.swiper = new Swiper(this.$slider, config)

    this.handleResize(this.swiper)
    $(window).on('resize', () => {
      this.handleResize(this.swiper)
    })
  }

  handleResize (swiper) {
    let slidesCount = this.swiper.slides.length - this.swiper.loopedSlides * 2
    if (window.innerWidth >= 1024 && slidesCount <= 3) {
      this.swiper.detachEvents()
      this.$buttonNext.hide()
      this.$buttonPrev.hide()
    } else {
      this.swiper.attachEvents()
      this.$buttonNext.show()
      this.$buttonPrev.show()
    }
  }
}

window.customElements.define('flynt-slider-image', SliderImage, { extends: 'div' })
